import React, { useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Subheading from "../../components/Subheading"
import Pagination from "../../components/Pagination"
import NewsCards from "./../../components/Cards/NewsList"
import { graphql } from "gatsby"
import "./../../styles/dark-mode.scss"

export default ({
  data: {
    pageInfo: { title, description },
    newsDescendingOrder: { nodes: newsDescendingOrder },
  },
}) => {
  const newsPerPage = 9
  const newsPagesCount = Math.ceil(newsDescendingOrder.length / newsPerPage)
  const getNewsItems = sortBy =>
    sortBy === "newest"
      ? newsDescendingOrder
      : [...newsDescendingOrder].reverse()
  const [sortBy, setSortBy] = useState("newest")
  const [currentPageNewsItems, setCurrentPageNewsItems] = useState([
    ...getNewsItems(sortBy).slice(0, newsPerPage),
  ])

  return (
    <Layout fluid={false} pageInfo={{ pageName: "First Responders News" }}>
      <SEO title="First Responders News" keywords={[`news`, `detail`]} />
      <Subheading title={title}>{description}</Subheading>
      <Pagination
        sortMode={sortBy}
        onClickSort={sortBy => {
          setSortBy(sortBy)
          setCurrentPageNewsItems([
            ...getNewsItems(sortBy).slice(0, newsPerPage),
          ])
        }}
        urlPrefix={`/first-responders/news`}
        currentPage={1}
        numPages={newsPagesCount}
      />
      <NewsCards isFirstResponders={true} items={currentPageNewsItems} />
      <Pagination
        sorting={false}
        urlPrefix={`/first-responders/news`}
        currentPage={1}
        numPages={newsPagesCount}
      />
    </Layout>
  )
}

export const FirstRespondersNewsQuery = graphql`
  query FirstRespondersNewsQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-news" }) {
      title
      description
      featuredCard
      maxCardLimit
    }
    newsDescendingOrder: allNodeNews(sort: { order: DESC, fields: [created] }) {
      nodes {
        id
        title
        caption: field_caption
        source: field_source
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
